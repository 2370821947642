import React, { FC } from "react";
import {
  FiGlobe,
  FiLinkedin,
  FiGithub,
  FiTwitter,
  FiInstagram,
} from "react-icons/fi";
import { IconType } from "react-icons";
import {
  ContentTeamMember,
  ContentTeamMemberLink,
} from "../models/content.model";
import classes from "../styles/components/TeamMemberLink.module.scss";

interface ITeamMemberLinkProps {
  link: ContentTeamMemberLink;
  teamMember: ContentTeamMember;
}

const TeamMemberLink: FC<ITeamMemberLinkProps> = (props) => {
  let Icon: IconType;
  switch (props.link.source) {
    case "linkedin":
      Icon = FiLinkedin;
      break;
    case "github":
      Icon = FiGithub;
      break;
    case "twitter":
      Icon = FiTwitter;
      break;
    case "instagram":
      Icon = FiInstagram;
      break;
    case "website":
    default:
      Icon = FiGlobe;
      break;
  }
  return (
    <a
      href={props.link.url}
      aria-label={`${props.link.source} ${props.teamMember.name}`}
    >
      <Icon className={classes["icon"]} />
    </a>
  );
};

export default TeamMemberLink;
